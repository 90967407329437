import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import NavBar from "./components/NavBar";
import { Container } from "@mui/material";

const App: React.FC = () => {
  const routing = useRoutes(routes);

  return (
      <div>
          <div>
              <NavBar />
              <Container>
                  {routing}
              </Container>
          </div>
      </div>
  );
};

export default App;
