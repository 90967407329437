import React from 'react';
import SubtitleBlock from './SubtitleBlock';
import { ITextBlock } from "../interfaces/IFileUpload";

interface SubtitleListProps {
    data: ITextBlock[][];
    onTextChange: (index: number, newText: ITextBlock[]) => void;
}

const SubtitleList: React.FC<SubtitleListProps> = ({ data, onTextChange }) => {
    return (
        <div>
            {data.map((block, index) => (
                <SubtitleBlock key={index} block={block} blockIndex={index} onTextChange={onTextChange} />
            ))}
        </div>
    );
};

export default SubtitleList;
