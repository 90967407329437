export const STORAGE_KEYS = {
    GROUP_SIZE: 'groupSize'
};

export const save = <T>(key: string, value: T): void => {
    try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Error saving to localStorage', error);
    }
};

export const load = <T>(key: string): T | undefined => {
    try {
        const serializedValue = localStorage.getItem(key);
        if (serializedValue === null) {
            return undefined;
        }
        return JSON.parse(serializedValue) as T;
    } catch (error) {
        console.error('Error loading from localStorage', error);
        return undefined;
    }
};

export const remove = (key: string): void => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing from localStorage', error);
    }
};
