import React, { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NotificationProvider } from './context/NotificationContext';
import { DialogProvider } from './context/DialogContext';

interface AppProvidersProps {
    children: ReactNode;
}

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
    return (
        <BrowserRouter>
            <NotificationProvider>
                <DialogProvider>
                    {children}
                </DialogProvider>
            </NotificationProvider>
        </BrowserRouter>
    );
};

export default AppProviders;
