import React from 'react';
import FileUploader from "../components/FileUploader";

const HomePage: React.FC = () => {
    return <div>

        <FileUploader />
    </div>;
};

export default HomePage;
