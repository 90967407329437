import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const NavBar: React.FC = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                {/*<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>*/}
                {/*    MyApp*/}
                {/*</Typography>*/}
                {/*<Button color="inherit" component={Link} to="/">*/}
                {/*    Home*/}
                {/*</Button>*/}
                {/*<Button color="inherit" component={Link} to="/about">*/}
                {/*    About*/}
                {/*</Button>*/}
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
