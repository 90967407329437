import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';

interface DialogContextProps {
    showInfoDialog: (message: string) => void;
    showConfirmDialog: (message: string, onConfirm: () => void) => void;
    showErrorDialog: (message: string) => void;
    showSuccessDialog: (message: string) => void;
    showWarningDialog: (message: string) => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export const useDialog = (): DialogContextProps => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error('useDialog must be used within a DialogProvider');
    }
    return context;
};

interface DialogProviderProps {
    children: ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
    const [dialog, setDialog] = useState<{ type: 'info' | 'confirm' | 'error' | 'success' | 'warning'; message: string; onConfirm?: () => void } | null>(null);

    const showInfoDialog = (message: string) => {
        setDialog({ type: 'info', message });
    };

    const showConfirmDialog = (message: string, onConfirm: () => void) => {
        setDialog({ type: 'confirm', message, onConfirm });
    };

    const showErrorDialog = (message: string) => {
        setDialog({ type: 'error', message });
    };

    const showSuccessDialog = (message: string) => {
        setDialog({ type: 'success', message });
    };

    const showWarningDialog = (message: string) => {
        setDialog({ type: 'warning', message });
    };

    const handleClose = () => {
        setDialog(null);
    };

    const handleConfirm = () => {
        if (dialog?.onConfirm) {
            dialog.onConfirm();
        }
        setDialog(null);
    };

    const getColor = (type: string) => {
        switch (type) {
            case 'info':
                return 'primary.main';
            case 'confirm':
                return 'secondary.main';
            case 'error':
                return 'error.main';
            case 'success':
                return 'success.main';
            case 'warning':
                return 'warning.main';
            default:
                return 'primary.main';
        }
    };

    return (
        <DialogContext.Provider value={{ showInfoDialog, showConfirmDialog, showErrorDialog, showSuccessDialog, showWarningDialog }}>
            {children}
            {dialog && (
                <Dialog open={true} onClose={handleClose}>
                    <DialogTitle>
                        <Box color={getColor(dialog.type)}>
                            {dialog.type === 'info' && 'Information'}
                            {dialog.type === 'confirm' && 'Confirmation'}
                            {dialog.type === 'error' && 'Error'}
                            {dialog.type === 'success' && 'Success'}
                            {dialog.type === 'warning' && 'Warning'}
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>{dialog.message}</Typography>
                    </DialogContent>
                    <DialogActions>
                        {dialog.type === 'confirm' && (
                            <Button onClick={handleConfirm} color="primary">
                                Confirm
                            </Button>
                        )}
                        <Button onClick={handleClose} color="primary" autoFocus>
                            {dialog.type === 'info' ? 'Close' : 'Cancel'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </DialogContext.Provider>
    );
};
