import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography, TextField, Box, Grid } from '@mui/material';
import { ITextBlock } from "../interfaces/IFileUpload";
import { useDialog } from "../context/DialogContext";

interface SubtitleBlockProps {
    block: ITextBlock[];
    onTextChange: (index: number, newText: ITextBlock[]) => void;  // New prop
    blockIndex: number;  // New prop to identify the block
}

const SubtitleBlock: React.FC<SubtitleBlockProps> = ({ block, onTextChange, blockIndex }) => {
    const [copied, setCopied] = useState(false);
    const [minIndex, setMinIndex] = useState<number | null>(null);
    const [maxIndex, setMaxIndex] = useState<number | null>(null);
    const [newText, setNewText] = useState('');  // New state for textarea
    const { showErrorDialog } = useDialog();

    useEffect(() => {
        const indices = block.map(item => parseInt(item.i));
        setMinIndex(Math.min(...indices));
        setMaxIndex(Math.max(...indices));
    }, [block]);

    const handleCopy = () => {
        const textToCopy = "Dịch sang tiếng Việt, dịch theo format json tôi cho\n" + JSON.stringify(block, null, 2);
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopied(true);
        });
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        handleChange(text);
    };

    const handlePaste = () => {
        navigator.clipboard.readText().then((text) => {
            handleChange(text);
        });
    };

    const handleChange = (text: string) => {
        if (newText.length && !text) {
            return;
        }
        if (!isValidTextBlockArray(text)) {
            showErrorDialog('Nội dung dịch không hợp lệ, vui lòng kiểm tra lại');
            return;
        }
        setNewText(text);
        onTextChange(blockIndex, JSON.parse(text) as ITextBlock[]);  // Call the callback with the new text
    }

    const isValidTextBlockArray = (text: string): boolean => {
        try {
            const parsedValue = JSON.parse(text);
            if (!Array.isArray(parsedValue)) {
                return false;
            }

            if (parsedValue.length === 0) {
                return false;
            }

            const isValid = parsedValue.every((item: any) => {
                return (
                    typeof item.i === 'string' &&
                    typeof item.t === 'string'
                );
            });

            if (!isValid) {
                return false;
            }

            const firstItem = parsedValue[0];
            const lastItem = parsedValue[parsedValue.length - 1];

            return (
                parseInt(firstItem.i) === minIndex &&
                parseInt(lastItem.i) === maxIndex
            );
        } catch (e) {
            return false;
        }
    };

    return (
        <Card style={{ marginBottom: '1rem' }}>
            <CardContent>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={5}>
                        <Typography variant="h6">
                            Index Range: {minIndex !== null ? minIndex : ''} - {maxIndex !== null ? maxIndex : ''}
                        </Typography>
                        <Box display="flex">
                            <Button variant="contained" color={copied ? "secondary" : "primary"} onClick={handleCopy}>
                                {copied ? 'Copied!' : 'Copy'}
                            </Button>
                            <Button
                                sx={{
                                    marginLeft: 2,
                                }}
                                variant="contained"
                                color="primary"
                                onClick={handlePaste}
                            >
                                Paste
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            label="New Text"
                            multiline
                            value={newText}
                            onChange={handleTextChange}
                            fullWidth
                            sx={{ mt: 2 }}
                            maxRows={3}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SubtitleBlock;
