import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Alert, Snackbar } from "@mui/material";

interface NotificationContextProps {
    showError: (message: string) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotification = (): NotificationContextProps => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};

interface NotificationProviderProps {
    children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
    const [error, setError] = useState<string | null>(null);
    const [open, setOpen] = useState(false);

    const showError = (message: string) => {
        setError(message);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setError(null);
    };

    return (
        <NotificationContext.Provider value={{ showError }}>
            {children}
            {error && (
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            )}
        </NotificationContext.Provider>
    );
};
