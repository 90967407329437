import React, { useEffect, useState } from 'react';
import SubtitleList from "./SubtitleList";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { ISubtitleBlock, ITextBlock } from "../interfaces/IFileUpload";
import { load, save, STORAGE_KEYS } from "../helpers/localStorageHelper";
import ExampleComponent from "./ExampleComponent";

const FileUploader: React.FC = () => {
    const [textBlocks, setTextBlocks] = useState<ITextBlock[][]>([]);
    const [subtitleBlocks, setSubtitleBlocks] = useState<ISubtitleBlock[]>([]);
    const [filename, setFilename] = useState<string>('');
    // const [groupSize, setGroupSize] = useState<string>('50');
    const [groupSize, setGroupSize] = useState<string>(() => load<string>(STORAGE_KEYS.GROUP_SIZE) || '50');
    const [newTextBlocks, setNewTextBlocks] = useState<ITextBlock[][]>([]);

    useEffect(() => {
        save(STORAGE_KEYS.GROUP_SIZE, groupSize);
    }, [groupSize]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFilename(file.name);
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result as string;
                const { textBlocks, timeBlocks } = parseSubtitles(text.replace(/\r/g, ''));
                console.log("=>(FileUploader.tsx:28) textBlocks", textBlocks);
                setSubtitleBlocks(timeBlocks);
                const tb = groupTextBlocks(textBlocks, Number(groupSize));
                setTextBlocks(tb);
                setNewTextBlocks(tb);
            };
            reader.readAsText(file);
        }
    };

    const parseSubtitles = (subtitles: string): { textBlocks: ITextBlock[], timeBlocks: ISubtitleBlock[] } => {
        const subtitleBlocks = subtitles.trim().split('\n\n');
        const textBlocks: any = [];
        const timeBlocks: any = [];

        subtitleBlocks.forEach(block => {
            const lines = block.split('\n');
            const i = lines[0];
            const s = lines[1];
            const t = lines.slice(2).join(' ');

            textBlocks.push({ i, t });
            timeBlocks.push({ i, s });
        });

        return { textBlocks, timeBlocks };
    }

    const groupTextBlocks = (textBlocks: ITextBlock[], groupSize: number): ITextBlock[][] => {
        const result: ITextBlock[][] = [];
        let currentGroup: ITextBlock[] = [];

        textBlocks.forEach((block, index) => {
            currentGroup.push(block);

            if ((index + 1) % groupSize === 0) {
                result.push(currentGroup);
                currentGroup = [];
            }
        });

        if (currentGroup.length > 0) {
            result.push(currentGroup);
        }

        return result;
    }

    function mergeToSRT(textBlocks: ITextBlock[], subtitleBlocks: ISubtitleBlock[]): string {
        let srtContent = '';

        // Loop through each textBlock
        for (let i = 0; i < textBlocks.length; i++) {
            const textBlock = textBlocks[i];
            const subtitleBlock = subtitleBlocks.find(sb => sb.i === textBlock.i);

            if (subtitleBlock) {
                srtContent += `${i + 1}\n`;
                srtContent += `${subtitleBlock.s}\n`;
                srtContent += `${textBlock.t}\n\n`;
            }
        }

        return srtContent.trim(); // Trim to remove extra newline at the end
    }

    const handleExport = () => {
        const result: ITextBlock[] = [];
        newTextBlocks.forEach(textBlocks => {
            result.push(...textBlocks);
        });
        const srtContent = mergeToSRT(result, subtitleBlocks);
        saveSRTToFile(srtContent, filename);
    }

    function saveSRTToFile(srtContent: string, fileName: string): void {
        // Create a Blob from the string content
        const blob = new Blob([srtContent], { type: 'text/plain' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a link element
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;

        // Append the link to the body
        document.body.appendChild(a);

        // Programmatically trigger a click event on the link
        a.click();

        // Cleanup: remove the link and revoke the URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    const handleGroupSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!isNaN(Number(value)) && Number(value) >= 0) {
            setGroupSize(value);
        }
    };

    const handleTextChange = (index: number, newText: ITextBlock[]) => {
        try {
            const updatedNewTextBlocks = [...newTextBlocks];
            updatedNewTextBlocks[index] = newText;
            setNewTextBlocks(updatedNewTextBlocks);
        } catch (e) {
            console.error("Translate lỗi nè: ", e);
        }
    };

    return (
        <Container>
            <input
                accept=".srt"
                id="file-input"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <label htmlFor="file-input">
                <Button variant="contained" component="span" sx={{
                    mt: 2,
                }}>
                    Upload SRT File
                </Button>
            </label>

            <TextField
                label="Group Size"
                type="number"
                value={groupSize}
                onChange={handleGroupSizeChange}
                InputProps={{ inputProps: { min: 0 } }}
                sx={{ mt: 2, mx: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleExport} style={{ marginTop: '1rem' }}>
                Export srt
            </Button>
            <Typography variant="h3" style={{ marginTop: '1rem' }}>Subtitles</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <SubtitleList data={textBlocks} onTextChange={handleTextChange} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default FileUploader;
